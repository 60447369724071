var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{attrs:{"title":"Agenda","options":_vm.options,"rotas":_vm.rotas}},[_c('div',{staticClass:"expande-horizontal pt-2",staticStyle:{"background-color":"#f2f2f2","min-height":"90vh","align-items":"flex-start"},attrs:{"dark":_vm.$theme.dark,"light":_vm.$theme.light}},[_c('v-flex',{staticClass:"px-3 fonte",attrs:{"xs12":"","md3":""}},[_c('v-text-field',{staticClass:"fonte-bold",attrs:{"hide-details":"","filled":"","dense":"","append-icon":"mdi-magnify","placeholder":"Pesquise um agendamento"},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),(_vm.filtereds.length > 0)?_c('v-list',{staticClass:"px-0 ma-0 fonte windows-style-content pr-2",staticStyle:{"max-height":"88vh","overflow":"auto"},attrs:{"color":"transparent"}},_vm._l((_vm.filtereds),function(event){return _c('v-list-item',{key:event._id,staticClass:"px-2 mx-0",class:{
            'list-events-item': _vm.get_external_movimentation._id !== event._id,
            'list-events-item-selected elevation-6':
              _vm.get_external_movimentation._id === event._id
          },on:{"click":function($event){return _vm.openEvent(event)}}},[(_vm.get_external_movimentation._id === event._id)?_c('v-icon',{staticClass:"mr-3",attrs:{"size":"23","color":"orange"}},[_vm._v("mdi-eye-circle-outline")]):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"fonte-bold",class:{
                  'white--text': _vm.get_external_movimentation._id === event._id
                },staticStyle:{"font-size":"14pt"}},[_vm._v(_vm._s(_vm._f("typeFilter")(event.type)))]),_c('span',{staticClass:"grey--text ml-2",staticStyle:{"font-size":"10pt"}},[_vm._v(_vm._s(event.visit_date)+" "+_vm._s(event.visit_hour))])]),(_vm.get_external_movimentation._id === event._id)?_c('v-list-item-subtitle',{class:{
                'orange--text fonte-bold':
                  _vm.get_external_movimentation._id === event._id
              }},[_vm._v(" Você está visualizando ao lado ")]):_c('v-list-item-subtitle',{class:{
                'grey--text': _vm.get_external_movimentation._id === event._id
              }},[_vm._v(" "+_vm._s(event.name)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"text":"","dark":"","color":_vm.getColor(event.status),"small":"","depressed":"","rounded":""}},[_c('span',{staticClass:"fonte-bold",staticStyle:{"padding-top":"2px"}},[_vm._v(_vm._s(_vm.getStatus(event.status)))]),_c('v-icon',{attrs:{"size":"15"}},[_vm._v(" "+_vm._s(_vm.getIcon(event.status))+" ")])],1)],1)],1)}),1):_c('div',{staticClass:"pa-12 expande-horizontal animate__animated animate__fadeInUp centraliza column",staticStyle:{"min-height":"30vh"}},[_c('v-icon',{attrs:{"size":"42"}},[_vm._v("mdi-calendar-alert-outline")]),_c('span',{staticClass:"fonte-bold text-center",staticStyle:{"font-size":"16pt"}},[_vm._v("Nenhum agendamento encontrado!")]),_c('span',{staticClass:"fonte grey--text text-center"},[_vm._v("Para criar um novo agendamento, clique no número do dia no calendário")])],1)],1),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-flex',{attrs:{"xs12":"","md9":""}},[_c('EventDialog',{ref:"eventDialog"}),(_vm.get_external_movimentation._id)?_c('ViewEvent',{ref:"vEvent"}):_c('Calendar',{attrs:{"events":_vm.get_external_movimentations},on:{"create-event":date => _vm.$refs.eventDialog.open(date, 'create-event'),"open-event":event => _vm.$refs.eventDialog.open(event, 'open-event')}})],1):_c('v-dialog',{attrs:{"transition":"slide-x-transition","fullscreen":"","value":_vm.get_external_movimentation._id ? true : false}},[_c('v-card',[(_vm.get_external_movimentation._id)?_c('ViewEvent',{ref:"vEvent"}):_vm._e()],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }