<template>
  <div>
    <v-sheet height="52" color="#f2f2f2" class="pt-0">
      <div
        :class="{
          'mx-1': $vuetify.breakpoint.smAndDown
        }"
        class="pb-1 expande-horizontal centraliza"
      >
        <div
          class="mb-1"
          :style="
            `display: flex; flex-wrap: nowrap;border-radius: 10px; background: ${$theme.primary}; padding: 6px;`
          "
        >
          <v-btn
            dark
            style="border-radius: 7px;"
            @click="type = 'day'"
            :color="type == 'day' ? 'white' : $theme.primary"
            :class="{
              'elevation-0': type != 'day',
              'elevation-1 black--text': type == 'day'
            }"
            class="text-capitalize mr-1 fonte"
            >dia</v-btn
          >
          <v-btn
            dark
            style="border-radius: 7px;"
            @click="type = 'week'"
            :color="type == 'week' ? 'white' : $theme.primary"
            :class="{
              'elevation-0': type != 'week',
              'elevation-1 black--text': type == 'week'
            }"
            class="text-capitalize mx-1 fonte"
            >semana</v-btn
          >
          <v-btn
            dark
            style="border-radius: 7px;"
            @click="type = 'month'"
            :color="type == 'month' ? 'white' : $theme.primary"
            :class="{
              'elevation-0': type != 'month',
              'elevation-1 black--text': type == 'month'
            }"
            class="text-capitalize ml-1 fonte"
            >mês</v-btn
          >
        </div>
        <v-spacer></v-spacer>
        <v-avatar
          style="cursor: pointer;"
          size="32"
          class="mr-2"
          :color="$theme.primary"
          @click="$refs.calendar.prev()"
        >
          <v-icon color="#f2f2f2">mdi-chevron-left</v-icon>
        </v-avatar>
        <div
          class="centraliza"
          style="padding-top: 6px; display: flex; min-width: 220px;"
        >
          <span
            v-if="$refs.calendar"
            style="white-space: nowrap; font-size: 23pt;"
            class="mr-2 fonte-bold text-lowercase"
          >
            {{ $refs.calendar.title.split(" ")[0] }}
          </span>
          <span
            v-if="$refs.calendar"
            style="white-space: nowrap; font-size: 20pt;"
            class="mr-2 fonte text-lowercase"
          >
            de
          </span>
          <span
            v-if="$refs.calendar"
            style="white-space: nowrap; font-size: 19pt;"
            class="fonte text-lowercase"
          >
            {{ $refs.calendar.title.split(" ")[1] }}
          </span>
        </div>

        <v-avatar
          style="cursor: pointer;"
          size="32"
          :color="$theme.primary"
          class="ml-2"
          @click="$refs.calendar.next()"
        >
          <v-icon color="#f2f2f2">mdi-chevron-right</v-icon>
        </v-avatar>
        <v-spacer></v-spacer>
        <span style="width: 300px;"></span>
      </div>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        locale="pt-br"
        :type="type"
        :events="getEvents"
        event-overlap-mode="stack"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="$emit('open-event', $event.event)"
        @click:more="openDaySelected($event)"
        @click:date="$emit('create-event', $event)"
        @change="$forceUpdate()"
      ></v-calendar>
    </v-sheet>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    type: "day",
    types: [
      {
        name: "Mês todo",
        value: "month"
      },
      {
        name: "Semana",
        value: "week"
      },
      {
        name: "Dia",
        value: "day"
      }
    ],
    weekday: [1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Segunda até Sábado", value: [1, 2, 3, 4, 5, 6] },
      { text: "Segunda até Domingo", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Segunda até Sexta", value: [1, 2, 3, 4, 5] }
    ],
    value: ""
  }),
  computed: {
    getEvents() {
      return this.events.map(event => {
        return {
          ...event,
          start: this.$moment(
            event.visit_date + event.visit_hour || "00:00",
            "DD/MM/YYYYHH:mm"
          ).format("YYYY-MM-DD")
        };
      });
    }
  },
  methods: {
    openDaySelected(date) {
      this.type = "day";
      this.value = date.date;
    },
    getEventColor(event) {
      let status_type = {
        waiting: "orange",
        on_the_way: "blue",
        done: "green",
        canceled: "red"
      };
      return status_type[event.status] || "grey darken-3";
    }
  }
};
</script>
